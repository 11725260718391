import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import Icon from "@mdi/react"
import {
  mdiIdeogramCjk,
  mdiLanguageGo,
  mdiOpenInNew,
  mdiReact,
  mdiMonitorDashboard,
} from "@mdi/js"
import { Technologist } from "react-blobmoji"

const Work = props => {
  const { link, title, description, icon } = props
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      className="block p-4 active:bg-zinc-100 lg:hover:bg-zinc-100 lg:active:bg-zinc-200"
    >
      <div className="text-zinc-800 text-lg font-bold flex justify-between lg:justify-start items-center">
        <div className="">
          <span className="inline">
            {title}
            <Icon
              className="inline align-baseline ml-1"
              path={mdiOpenInNew}
              size={0.5}
            />
          </span>
        </div>
        <span className="ml-2 bg-zinc-100 p-[2px]">
          <Icon path={icon} size={1} />
        </span>
      </div>
      <div className="text-sm text-zinc-600">{description}</div>
    </a>
  )
}

const WorksPage = () => {
  return (
    <Layout>
      <Seo
        title="公開している制作物など"
        description="公開している制作物やコントリビューションなどの紹介です。"
        image="/shigoto_zaitaku_kodomo_man.png"
      />
      <div className="container mx-auto">
        <div className="p-4">
          <h1 className="text-3xl font-bold flex items-center">
            <Technologist size={32} />
            <span className="ml-2 ">公開している制作物など</span>
          </h1>
        </div>
        <Work
          link="https://github.com/miiton/Cica"
          title="Cica"
          icon={mdiIdeogramCjk}
          description="判読性を重視しながら合成および調整したプログラミング用日本語等幅フォントです。元フォントの作者様方に感謝。"
        />
        <Work
          link="https://github.com/miiton/kanaconv"
          title="github.com/miiton/kanaconv"
          icon={mdiLanguageGo}
          description="ひらがな、カタカナ、全角、半角を相互に変換するライブラリです。データクレンジングで重宝しています。"
        />
        <Work
          link="https://amedas.miiton.dev/"
          title="amedas_exporter"
          icon={mdiLanguageGo}
          description="気象庁がアメダスのデータをこっそりWebサイトで出力してくれているので、それをもとにPrometheusのexporterにしてみました。metricsの公開のみです。"
        />
        <Work
          link="https://github.com/miiton/miilog"
          title="github.com/miiton/miilog"
          icon={mdiLanguageGo}
          description="zapラッパー。Go言語のBlazing fastなログライブラリgithub.com/uber-go/zap をさっと使いやすくしたものです。Grafana Lokiに送る機能もつけました。"
        />
        <Work
          link="https://github.com/miiton/react-blobmoji"
          title="react-blobmoji"
          icon={mdiReact}
          description="Blobmojiをサイトで使いたくて作りました。"
        />
        <Work
          link="https://meilisearch-example-jp.miiton.dev/"
          title="MeiliSearchの日本語サンプルサイト"
          icon={mdiReact}
          description="MeiliSearchが日本語できちんと動作するかを確認するために公開しているサイトです。データには文化庁が公開している「国指定文化財等データベース」を使用させていただいています。（余談ですが、件数が35,000件強あって緯度経度も含まれていて説明文もあってものすごく貴重で使いやすいデータセットです。文化庁すごい。）"
        />
      </div>
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              headline: "公開している制作物など",
              image: ["https://miiton.dev/shigoto_zaitaku_kodomo_man.png"],
              datePublished: "2022-07-10T16:30:00+09:00",
              dateModified: "2022-07-10T16:30:00+09:00",
              author: {
                "@type": "Person",
                name: "miiton",
                url: "https://miiton.dev",
                sameAs: [
                  "https://github.com/miiton",
                  "https://twitter.com/miiton",
                ],
              },
            }),
          },
        ]}
      />
    </Layout>
  )
}

export default WorksPage
